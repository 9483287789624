(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.user.controller:ChangeUsernameCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.home.user')
  .controller('ChangeUsernameCtrl', ChangeUsernameCtrl);

  function ChangeUsernameCtrl($state, $mdToast, $filter, Username, AuthenticationService,$scope) {
    var vm = this;
    vm.ctrlName = 'ChangeUsernameCtrl';
    vm.changeUsernameModel = {};
    vm.username2 = undefined;



    vm.changeUsername = function () {
      Username.update({}, vm.changeUsernameModel,
        function () {
          AuthenticationService.logout();
          $state.go('authentication.login', {}, {reload: true});
          $mdToast.show(
            $mdToast.simple()
            .textContent($filter('translate')('SUCCESSFUL_EMAIL_CHANGE'))
            .position('bottom left')
            .hideDelay(5000)
          );
        }, function (error) {
          var errorMessage;
          if (error.status === 400) {
            errorMessage = 'INVALID_EMAIL';
          }
          else if (error.status === 409) {
            errorMessage = 'INVALID_PASSWORD';
          } else {
            errorMessage = 'SERVER_ERROR'
          }
          $mdToast.show(
            $mdToast.simple()
            .textContent($filter('translate')(errorMessage))
            .position('bottom left')
            .hideDelay(5000)
          );
        });
    }
  }
}());
